.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    background-color: #fff;
    color: #000;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
}

.not-found h1 {
    font-size: 3em;
    margin-bottom: 0;
}

.not-found p {
    margin-top: 0;
}

@media (max-width: 600px) {
    .not-found h1 {
        font-size: 2em;
    }
}