/* .projects-container {
    column-count: 1;
    column-gap: 30px;
    padding: 10px 40px;
    margin: auto;
}

@media (min-width: 700px) {
    .projects-container {
        column-count: 2;
    }
} */

/* .project-card {
    display: inline-block;
    width: 100%;
    break-inside: avoid;
    margin-bottom: 30px;
} */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  padding: 10px 40px;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
