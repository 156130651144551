.project-card {
  cursor: default;
  user-select: none;
  display: flex;
  gap: 10px;
  z-index: 0;
}

@media (max-width: 500px) {
  .project-card {
    flex-direction: column;
  }

  .media-card {
    margin: 0px 60px;
  }
}

.media-card {
  flex: 0.63;
  position: relative;
}

.media-card::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: 177.77%;
  /* Maintain a 9:16 aspect ratio */
}

.media-card-long {
  flex: 0.63;
  position: relative;
}

.media-card-long::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: 216.216%;
  /* Maintain a 9:16 aspect ratio */
}

.media-card img,
.media-card-long img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background-color: #f1f1f1;
  z-index: 0;
}

.media-card .overlay,
.media-card-long .overlay {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.description-card {
  flex: 1;
  /* height: 100%;
  min-height: 0; */
}

.description-card-content {
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
  min-height: 0;
}

.description-card-content p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  background-color: inherit;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: inherit;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: inherit;
}

.card-header a {
  width: 22px;
  height: 22px;
  align-self: flex-start;
  cursor: pointer;
  background-color: inherit;
}

.card-header img.open-icon {
  width: 22px;
  height: 22px;
  background-color: inherit;
}

.card-header h2 {
  font-size: 20px;
  font-weight: 600;
  background-color: inherit;
}

.card-header img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: inherit;
  background-color: #fff;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  background-color: inherit;
}

.tag {
  background-color: #d9d9d9;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
}
