* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "Inconsolata", monospace;
}

body {
  max-width: 1200px;
  margin: auto;
  background: #fff;
  overflow-y: scroll; /* Always show vertical scrollbar */
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
