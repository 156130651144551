.navbar {
  position: sticky;
  width: 100%;
  top: 0;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  background-color: transparent;
  z-index: 100;
}

.navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar-header-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px 20px;
  /* margin-bottom: 20px; */
}

/* .navbar-header-container.scrolled {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  margin-bottom: 0px;
}

.navbar-header-container.scrolled h1 {
  margin-left: 20px;
} */

/* .navbar-header-container.scrolled .socials-container {
  margin-right: 20px;
} */

.navbar-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 800;
  cursor: default;
  user-select: none;
}

.socials-container {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.socials-container a {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 20px;
}

.socials-container a:hover {
  color: #aaa;
}

.socials-container a p {
  color: #000;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}

.socials-container .socials {
  display: flex;
  justify-content: center;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .socials-container {
    gap: 20px;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding-bottom: 20px;
  }
}

.scrollable-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  scrollbar-width: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0px 20px 20px 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.tab-bar {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.scrollable-container button {
  color: #333;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin: 5px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 18px;
  border-radius: 100px;
  user-select: none;
}

.tab-bar button:hover {
  color: #aaa;
}

.tab-bar button.active {
  background-color: #f1f1f1;
  color: black;
}

.scroll-indicator {
  position: absolute;
  right: 2%;
  top: 80%;
}

/* @media (max-width: 700px) {
  .navbar-container {
      display: none;
  }

  .navbar-header-container {
      display: flex;
      justify-content: space-between;
  }
} */
